import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/section"
import Columns from "../components/columns"
import sal from 'sal.js'
import Slider from "react-slick"
import HeroBg from "../../static/images/techbg.png"
import Contact from "../../static/images/contac.jpg"


const ContactPage = () => {
    useEffect(() => {
    // Update the document title using the browser API
        sal({
            threshold: 1
        });
    });


  return (
  <Layout>
    <SEO title="Stimulate | Contact page" />

    <section className = ' hero is-fullheight has-bg has-marginbottom-4 is-relative'>
        <img src={HeroBg} className='image-cover-full' style={{filter: 'hue-rotate(70deg)'}}></img>
      <div className='hero-body'>
        <div className='container'>
        <Columns classes = 'is-vcentered is-multiline'>
          <div className = 'column is-full' style={{paddingRight: '10%'}}>
          <h2 className='is-size-0 has-marginbottom-4'>We'd love to hear from you.<br/>Here's how you can reach us...</h2>
            <Columns>
                <div className='column'>
                <p className = 'is-size-4'><strong>Address</strong><br /> B-40, Sector-4, Noida,<br /> Uttar Pradesh 201301, India</p>
                </div>
                <div className='column'>
                <p className = 'is-size-4'><strong>Phones</strong><br />+91 880 009 8135</p>
                </div>
                <div className='column'>
                <p className = 'is-size-4'><strong>Email</strong><br />operations@stimulate.co.in</p>
                </div>
            </Columns>
            
            

          </div>
        </Columns>
        </div>
      </div>
    </section>


    <Section classes='  '>
        <h2 className = 'is-size-3 has-text-centered has-marginbottom-3 is-uppercase'>GivING  <strong>back to society</strong></h2>
        <Columns classes='is-vcentered is-variable is-8'>
          <div className = ' column is-three-fifths' >
            <p className = 'is-size-5'>In future a certain percentage of Stimulate's profit would go to empowering underprivileged children. The aim is not to create an NGO, but research and improve the ones which are already there. The focus would be to create an emotional space which feels like home to abandoned kids where they don't feel away from their families, deprived of love and care.<br></br><br></br><strong className = 'has-text-primary'>If you want to work with us to make lives better drop us an email at sunakshi@stimulate.co.in along with your resume and mention children in the subject line.</strong><br></br><br></br>The image on the right is from the series of installations designed to sensitize public about underprivileged children during founder's degree project at college. </p>
          </div>
          <div className = ' column is-two-fifths is-relative' >
            <img src={Contact} alt='' style={{width: '100%'}}></img>
          </div>
        </Columns>
    </Section>


  </Layout>
)
}

export default ContactPage
